"use client"

import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { alpha } from "@mui/material/styles"
import { ConfirmButton, Icons, Logo, SearchInput } from "@src/components"
import { ROUTES } from "@src/constants/routes"
import useProduct from "@src/services/use-product"
import { getDataFromResponse } from "@src/utils/api"
import { usePathname, useRouter } from "next/navigation"
import * as React from "react"
import AccountPopover from "./account-popover"
import BadgeIcon from "./badge-icon"
import MenuList from "./menu"
import { useLayoutContext } from "./provider"
import DrawerSearchMobile from "./search/search-drawer-mobile"
import SearchPopover from "./search/search-popover"

interface Props {
  onNavOpen: () => void
}

const TopNav = (props: Props) => {
  const { onNavOpen } = props
  const router = useRouter()
  const pathname = usePathname()

  const { Service: ProductService, searching: productSearching } = useProduct()

  const { config, user } = useLayoutContext()

  const trendingWords = React.useMemo(
    () => config?.trending_keywords || [],
    [config]
  )

  const [anchorSearch, setAnchorSearch] = React.useState(null)
  const [searchValue, setSearchValue] = React.useState("")
  const [searchList, setSearchList] = React.useState([])

  const [openSearchDrawer, setOpenSearchDrawer] = React.useState(false)
  const [openAccountPopover, setOpenAccountPopover] = React.useState(false)

  const accountAnchorEl = React.useRef(null)


  const ConfigMobilePageName = React.useMemo(
    () => [
      {
        path: ROUTES.RECRUITMENT,
        name: "Tuyển dụng"
      },
      {
        path: ROUTES.ABOUT_US,
        name: "About Us"
      },
      {
        path: ROUTES.MEMBERSHIP,
        name: "Membership"
      },
      {
        path: ROUTES.BRANCH,
        name: "Chi nhánh"
      },
      {
        path: ROUTES.FAQ,
        name: "FAQ"
      },
      {
        path: ROUTES.COLLECTION,
        name: "Collection"
      },
      {
        path: ROUTES.EVENT,
        name: "Sự kiện"
      },
      {
        path: ROUTES.PRODUCT,
        name: "Sản phẩm"
      },
      {
        path: ROUTES.PROFILE_ORDERS,
        name: "Quản lý đơn hàng",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_NOTIFICATION,
        name: "Thông báo",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_FAVORITE_PRODUCTS,
        name: "Sản phẩm yêu thích",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_INFO,
        name: "Thông tin tài khoản",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_ADDRESS,
        name: "Sổ địa chỉ",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_ADDRESS_ADD,
        name: "Tạo địa chỉ mới",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_ADDRESS_EDIT,
        name: "Cập nhật địa chỉ",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE_VOUCHER,
        name: "Voucher",
        isArrow: true
      },
      {
        path: ROUTES.PROFILE,
        name: "Trang cá nhân",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.CART_PAYMENT,
        name: "Mua hàng",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.CART,
        name: "Giỏ hàng",
        beforePath: "",
        isArrow: true
      },
      {
        path: ROUTES.BLOG,
        name: "Blog",
        beforePath: "",
        isArrow: true
      },
    ],
    []
  )

  const currentRoute = React.useMemo(
    () => ConfigMobilePageName.find(item => pathname.startsWith(item.path)),
    [pathname]
  )

  const isPathnameProfile = React.useMemo(
    () => pathname.startsWith(ROUTES.PROFILE),
    [pathname]
  )

  React.useEffect(() => {
    if (searchValue) {
      ; (async () => {
        const res = await ProductService.searchList({
          search: searchValue,
          pageSize: 5
        })
        const { data } = getDataFromResponse(res)
        setSearchList(data || [])
      })()
    }
  }, [searchValue])

  const handleRedirect = path => () => {
    router.push(path)
  }

  const handleSearchValue = value => {
    if (searchList?.length > 0) {
      setSearchList([])
    }
    setSearchValue(value)
  }

  const handleBlurSearch = event => {
    setAnchorSearch(null)
    setTimeout(() => {
      setSearchList([])
      setSearchValue("")
    }, 300)
  }

  const handleToggleSearchDrawer = (status: boolean) => () => {
    setOpenSearchDrawer(status ?? !openSearchDrawer)
  }

  const handleBackProfileMobile = () => {
    if (pathname === ROUTES.PROFILE) {
      router.push(ROUTES.HOME_PAGE)
    } else {
      router.push(ROUTES.PROFILE)
    }
  }

  return (
    <Box
      component="header"
      id="header"
    >
      <Box
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: theme => alpha(theme.palette.background.default, 1),
          top: 0,
          zIndex: theme => theme.zIndex.appBar,
          margin: "auto",
          padding: {
            xs: "0 16px",
            md: "0 36px",
            xl: "0 112px"
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          pt="16px"
          pb={{
            xs: "20px",
            md: 0
          }}
          sx={{
            height: {
              xs: "auto"
            },
            minHeight: {
              xs: 64
            },
            maxWidth: "xl",
            margin: "auto"
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="space-between"
            minWidth={200}
            sx={{
              marginBottom: {
                xs: 0,
                md: "16px"
              }
            }}
          >
            <Box
              display={{
                xs: "block",
                md: "none"
              }}
            >
              {isPathnameProfile ? (
                <Icons
                  name="arrow-left"
                  type="button"
                  onClick={handleBackProfileMobile}
                />
              ) : (
                <Icons name="hamburger" type="button" onClick={onNavOpen} />
              )}
            </Box>

            {/* mobile site */}
            <Box
              display={{
                xs: "block",
                md: "none"
              }}
              sx={{
                width: "100%",
                textAlign: "center"
              }}
              onClick={handleRedirect(currentRoute?.path || "/")}
            >
              {currentRoute?.name ? (
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "25px",
                    paddingLeft: {
                      xs: 0,
                      md: "32px"
                    }
                  }}
                >
                  {currentRoute?.name}
                </Typography>
              ) : (
                <Box
                  sx={{
                    height: 16,
                    "& svg": {
                      width: "100%",
                      height: "100%"
                    }
                  }}
                  role="button"
                  onClick={handleRedirect(ROUTES.HOME_PAGE)}
                >
                  <Logo />
                </Box>
              )}
            </Box>

            {/* desktop site */}
            <Box
              sx={{
                cursor: "pointer",
                display: {
                  xs: "none",
                  md: "flex"
                },
                alignItems: "center",
                "& svg": {
                  height: 32,
                  width: "auto"
                }
              }}
              role="button"
              onClick={handleRedirect("/")}
            >
              <Logo />
            </Box>

            {/* Search input component */}
            <>
              <SearchInput
                sx={{
                  display: {
                    xs: "none !important",
                    md: "flex !important"
                  },
                  maxWidth: "536px",
                  flex: 1,
                  paddingRight: "16px !important",
                  "& .MuiInputBase-input": {
                    height: "100%"
                  }
                }}
                iconPosition="end"
                placeholder="Tìm kiếm sản phẩm"
                defaultValue={searchValue}
                onChange={handleSearchValue}
                onFocus={event => setAnchorSearch(event.currentTarget)}
                onBlur={handleBlurSearch}
              />
              <SearchPopover
                id="heard-search-popover"
                searchValue={searchValue}
                open={Boolean(anchorSearch)}
                anchorEl={anchorSearch}
                searching={productSearching}
                searchList={searchList}
                suggestKeyword={trendingWords}
              />
            </>

            <Stack alignItems="center" spacing={1}>
              {/* Mobile */}
              <Stack
                display={{
                  xs: "flex",
                  md: "none"
                }}
                gap="8px"
                sx={{
                  "& .MuiIconButton-root": {
                    width: 32,
                    height: 32,
                    padding: "4px"
                  },
                  "& svg": {
                    color: "#434343"
                  }
                }}
              >
                {isPathnameProfile ? (
                  <>
                    <BadgeIcon
                      iconName="ecommerce"
                      badgeNumber={config?.total_cart_items || 0}
                      onClick={handleRedirect(ROUTES.CART)}
                    />
                    <Icons
                      name="home"
                      type="button"
                      onClick={handleRedirect(ROUTES.HOME_PAGE)}
                    />
                  </>
                ) : (
                  <>
                    <BadgeIcon
                      iconName="ecommerce"
                      badgeNumber={config?.total_cart_items || 0}
                      onClick={handleRedirect(ROUTES.CART)}
                    />
                    <Icons
                      name="search"
                      type="button"
                      onClick={() => setOpenSearchDrawer(true)}
                    />
                    <Icons
                      name="account"
                      type="button"
                      onClick={handleRedirect(ROUTES.SIGN_IN)}
                    />
                  </>
                )}
              </Stack>

              {/* Desktop */}
              <Stack
                display={{
                  xs: "none",
                  md: "flex"
                }}
                gap="24px"
                sx={{
                  "& .MuiIconButton-root": {
                    width: 48,
                    height: 48,
                    padding: "auto"
                  },
                  "& svg": {
                    color: "#434343"
                  }
                }}
              >
                <BadgeIcon
                  iconName="heart"
                  badgeNumber={user?.product_ids?.length || 0}
                  onClick={handleRedirect(ROUTES.PROFILE_FAVORITE_PRODUCTS)}
                />
                <BadgeIcon
                  iconName="notification"
                  badgeNumber={0}
                  onClick={handleRedirect(ROUTES.PROFILE_NOTIFICATION)}
                />
                <BadgeIcon
                  iconName="ecommerce"
                  badgeNumber={config?.total_cart_items || 0}
                  onClick={handleRedirect(ROUTES.CART)}
                />
                {user ? (
                  <div
                    ref={accountAnchorEl}
                  >
                    <ConfirmButton
                      sx={{
                        padding: "12px 20px !important",
                        height: "100%",
                        maxWidth: 160,
                        "& span.label": {
                          maxWidth: 100,
                          overflow: "hidden",
                          display: "block",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }
                      }}
                      label={<span className="label">{user?.full_name}</span>}
                      startIcon={
                        <Icons
                          sx={{
                            "& svg": {
                              color: "white !important"
                            }
                          }}
                          name="user"
                        />
                      }
                      onClick={() => setOpenAccountPopover(true)}
                    />
                  </div>
                ) : (
                  <ConfirmButton
                    sx={{
                      padding: "12px 20px !important",
                      height: "100%"
                    }}
                    label="Đăng Nhập"
                    startIcon={
                      <Icons
                        sx={{
                          "& svg": {
                            color: "white !important"
                          }
                        }}
                        name="user"
                      />
                    }
                    onClick={handleRedirect(ROUTES.SIGN_IN)}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction="column"
        justifyContent="space-between"
        pb={{
          xs: "20px",
          md: 0
        }}
        sx={{
          display: {
            xs: "none",
            md: "flex"
          },
          minHeight: {
            xs: 60
          },
          backdropFilter: "blur(6px)",
          backgroundColor: theme => alpha(theme.palette.background.default, 1),
       
          margin: "auto",
          padding: {
            xs: "0 16px",
            md: "0 36px",
            xl: "0 112px"
          },
          boxShadow: "4px 20px 20px -20px rgba(16, 24, 40, 0.06)",
          zIndex: 999
        }}
      >
        <MenuList currentPath={pathname} onRedirect={handleRedirect} />
      </Stack>
      <DrawerSearchMobile
        open={openSearchDrawer}
        toggleDrawer={handleToggleSearchDrawer}
        suggestKeyword={trendingWords}
      />
      <AccountPopover
        anchorEl={accountAnchorEl.current}
        open={!!openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
      />
    </Box>
  )
}

export default TopNav
