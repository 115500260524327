"use client"
import { styled } from "@mui/material/styles"
import Footer from "./footer"
import Header from "./header"
import { Suspense } from "react"
import FallbackLoading from "@src/components/FallbackLoading"
import { LayoutProvider } from './provider'

const LayoutRoot = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%"
}))

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%"
})

export const Layout = props => {
  const { isFooter = true, children } = props



  return (
    <Suspense fallback={<FallbackLoading />}>
      <LayoutProvider>
        <Header />
        <LayoutRoot>
          <LayoutContainer>
            <Suspense fallback={<FallbackLoading />}>{children}</Suspense>
          </LayoutContainer>
        </LayoutRoot>
        {isFooter && <Footer />}
      </LayoutProvider>
    </Suspense>
  )
}
