import React from "react"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

const FallbackLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 550px)",
        width: "100%"
      }}
    >
      {/* <CircularProgress /> */}
    </Box>
  )
}

export default FallbackLoading
