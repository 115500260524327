import { styled } from "@mui/system"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Link from "next/link"
import { useRouter } from "next/navigation"
import * as React from "react"
import { CancelButton, NextImage, PriceTypography } from "@src/components"
import { formatPrice } from "@src/utils/common"
import { LOCAL_KEYS, LocalStorage } from "@src/utils/storages"
import { ROUTES } from "@src/constants/routes"
import Skeleton from "@mui/material/Skeleton"

const LinkStyled = styled(Link)`
  color: #000;
  display: block;
  padding: 10px 0;
  text-decoration: none;
  &:hover {
    color: #000;
    font-weight: 600;
  }
`

const TagContainer = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: #f6f6f6;
  &:hover {
    background-color: #ededed;
  }
`

interface Props {
  searchValue: string
  searching: boolean
  searchList: any[]
  suggestKeyword: string[]
  onCloseDrawer?: () => void
}

const SearchItem = ({ data, onRedirectDetail }) => {
  const {
    image,
    name,
    description,
    original_price,
    promotion_price,
    discount_percent
  } = data || {}

  return (
    <Box
      role="button"
      sx={{
        padding: "0px 24px",
        marginLeft: "-24px",
        width: "calc(100% + 48px)",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#F6F6F6"
        }
      }}
      className="search-item-wrapper"
      onClick={onRedirectDetail}
    >
      <Stack
        justifyItems="center"
        alignItems="center"
        gap={2}
        className="item"
        sx={{
          borderBottom: "1px solid #E7E7E7",
          padding: "8px 0px"
        }}
      >
        <Box>
          <NextImage
            src={image}
            sx={{
              height: 64,
              width: 48,
              "& img": {
                borderRadius: "8px"
              }
            }}
          />
        </Box>
        <Box>
          <Stack gap={0.5}>
            <Typography variant="body2" component="span" color="#000">
              {name}
            </Typography>
            {description && (
              <Typography variant="body2" component="span">
                {description}
              </Typography>
            )}
          </Stack>
          <Stack gap={1.5}>
            <Typography variant="body2" component="span" fontWeight="700">
              {formatPrice(promotion_price || original_price)}
            </Typography>
            <PriceTypography
              variant="body2"
              component="span"
              fontWeight={700}
              isNull={!promotion_price}
            >
              {formatPrice(original_price)}
            </PriceTypography>
            {Number(discount_percent) > 0 && (
              <Typography
                variant="body2"
                component="span"
                color="#FF0000"
                fontWeight={700}
              >
                {discount_percent}%
              </Typography>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}


const SearchContent = (props: Props) => {
  const { searchValue, searching, searchList = [], suggestKeyword = [], onCloseDrawer } = props

  const router = useRouter()
  const searchKeywordHistory = React.useMemo(
    () => LocalStorage.get(LOCAL_KEYS.SEARCH_HISTORY) || [],
    [open, searching]
  )

  React.useEffect(() => {
    // detect in popper
    if (searchValue && searching) {
      const searchHistory = LocalStorage.get(LOCAL_KEYS.SEARCH_HISTORY) || []
      if (searchValue && !searchHistory.includes(searchValue)) {
        if (searchHistory.length >= 5) {
          searchHistory.pop()
        }
        searchHistory.unshift(searchValue)
        LocalStorage.set(LOCAL_KEYS.SEARCH_HISTORY, searchHistory)
      }
    }
  }, [searchValue, searching])

  const handleClearSearchHistory = React.useCallback(() => {
    LocalStorage.clear(LOCAL_KEYS.SEARCH_HISTORY)
  }, [])

  const handleRedirectSearchPage = React.useCallback(
    (path: string) => () => {
      onCloseDrawer?.()
      router.push(`${ROUTES.SEARCH}?keyword=${path}`)
    },
    [onCloseDrawer]
  )

  const handleRedirectDetail = React.useCallback(
    (slug: string) => () => {
      onCloseDrawer?.()
      router.push(`${ROUTES.PRODUCT}/${slug}`)
    },
    [onCloseDrawer]
  )

  // Loading
  if (searching) {
    return (
      <Stack
        flexDirection="column"
        justifyItems="center"
        justifyContent="center"
        py={1}
        gap="12px"
      >
        {new Array(6).fill(0).map((_, index) => (
          <Stack key={index} gap="12px">
            <Skeleton  animation="wave" sx={{
              height: 48,
              width: 48,
              borderRadius: "8px",
              transform: "initial"
            }} />
            <Box width="80%">
              <Skeleton animation="wave" height={20} width="100%" />
              <Skeleton animation="wave" height={32} width="100%" />
            </Box>
          </Stack>
        ))}

      </Stack>
    )
  }

  // No result
  if (searchValue && searchList.length === 0) {
    return (
      <Stack py={1}>
        <Typography variant="body2">Không tìm thấy kết quả</Typography>
      </Stack>
    )
  }

  // Has result
  if (searchValue && searchList.length > 0) {
    return (
      <Box>
        <Box
          sx={{
            "& .search-item-wrapper": {
              "&:last-child > .item": {
                borderBottom: "none"
              }
            }
          }}
        >
          {searchList.map((item, index) => (
            <SearchItem
              key={index}
              data={item}
              onRedirectDetail={handleRedirectDetail(item.slug)}
            />
          ))}
        </Box>
        <Stack justifyContent="center" pt={2}>
          <CancelButton
            type="button"
            label="Xem thêm"
            minHeight="32px"
            height="32px"
            minWidth="144px"
            sx={{
              color: "#262626 !important"
            }}
            onClick={handleRedirectSearchPage(searchValue)}
          />
        </Stack>
      </Box>
    )
  }

  // Default
  return (
    <>
      {searchKeywordHistory.length > 0 && (
        <Box>
          <Typography pb="12px" variant="body1" fontWeight="600">
            Tìm kiếm gần đây
          </Typography>
          {searchKeywordHistory.map((keyword, index) => (
            <LinkStyled
              key={index}
              py="10px"
              variant="body1"
              href={`/search?keyword=${keyword}`}
              onClick={onCloseDrawer}
            >
              {keyword}
            </LinkStyled>
          ))}
          <Button
            disableRipple
            variant="text"
            sx={{
              opacity: "0.6",
              background: "transparent",
              border: "none",
              fontWeight: 400,
              padding: "18px 0 28px",
              "&:hover": {
                background: "transparent",
                opacity: "1"
              }
            }}
            onClick={handleClearSearchHistory}
          >
            Xoá tất cả
          </Button>
        </Box>
      )}
      <Typography pb="20px" variant="body1" fontWeight="600">
        Từ khoá đề xuất
      </Typography>
      <Stack alignItems="center" gap={1.5} flexWrap="wrap">
        {suggestKeyword.map((keyword, index) => (
          <TagContainer key={index} onClick={handleRedirectSearchPage(keyword)}>
            {keyword}
          </TagContainer>
        ))}
      </Stack>
    </>
  )
}

export default SearchContent
