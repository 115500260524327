"use client"

import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Drawer from "@mui/material/Drawer"
import { useResponsive } from "@src/hooks"
import SearchContent from "./search-content"
import { Icons, SearchInput } from "@src/components"
import { useState, useEffect } from "react"
import { getDataFromResponse } from "@src/utils/api"
import useProduct from "@src/services/use-product"

export default function DrawerSearchMobile(props) {
  const { open, toggleDrawer, suggestKeyword } = props
  const { isMobile } = useResponsive()

  const [searchValue, setSearchValue] = useState("")
  const [searchList, setSearchList] = useState([])

  const { Service: ProductService, searching: productSearching } = useProduct()
  useEffect(() => {
    if (searchValue) {
      ;(async () => {
        const res = await ProductService.searchList({
          search: searchValue,
          pageSize: 5
        })
        const { data } = getDataFromResponse(res)
        setSearchList(data || [])
      })()
    } else {
      if (searchList?.length > 0) {
        setSearchList([])
      }
    }
  }, [searchValue])

  const handleSearchValue = value => {
    if (searchList?.length > 0) {
      setSearchList([])
    }
    setSearchValue(value)
  }

  if (!isMobile) return null

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          padding: "24px"
        }
      }}
      hideBackdrop
      open={open}
      onClose={toggleDrawer(false)}
    >
      <Box width="100%">
        <Stack mb={3} gap={2} position="relative">
          <Icons
            sx={{
              padding: 0
            }}
            type="button"
            name="arrow-left"
            onClick={toggleDrawer(false)}
          />
          <SearchInput
            iconPosition="start"
            placeholder="Tìm kiếm sản phẩm"
            sx={{
              borderRadius: "8px",
              border: "1px solid #E7E7E7",
              width: "100% !important",
              margin: "0px !important",
              "& svg": {
                opacity: 0.4,
                width: "24px",
                height: "24px"
              },
              "& .MuiInputBase-root": {
                height: "32px !important",
                fontSize: "14px !important",
                paddingRight: "30px !important"
              }
            }}
            defaultValue={searchValue}
            onChange={handleSearchValue}
          />
          {searchValue && (
            <Box
              role="button"
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
                borderRadius: "50%",
                "& svg": {
                  opacity: 0.7
                }
              }}
              onClick={() => setSearchValue("")}
            >
              <Icons name="x" />
            </Box>
          )}
        </Stack>
        <SearchContent
          searchValue={searchValue}
          searchList={searchList}
          searching={productSearching}
          suggestKeyword={suggestKeyword}
          onCloseDrawer={toggleDrawer(false)}
        />
      </Box>
    </Drawer>
  )
}
