import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Stack from "@mui/material/Stack"
import * as React from "react"
import { CustomButton, Icons } from "@src/components"
import { getClassNameActive } from "../config"

import { styled } from "@mui/system"

const SubMenuItem = styled(ListItemButton)(({ theme }) => ({
  color: "#141414 !important",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "24px",
  padding: "0 48px",
  minHeight: "32px !important",
  width: "calc(100% + 16px)",
  minWidth: 200,
  borderTop: "1px solid #E5E5E5",
  marginLeft: "-16px !important",
  "&.active": {
    color: "#000 !important",
    backgroundColor: "#F6F6F6 !important"
  }
}))

const SideNavWithSubMenu = props => {
  const { currentPath, data, onRedirect } = props || { data: {} }

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Stack
      sx={{
        // minHeight: 32,
        width: "100%",
        borderBottom: "1px solid #E5E5E5",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <CustomButton
        disableRipple
        label={data.title}
        sx={{
          backgroundColor: "transparent !important",
          border: "none !important",
          color: "#141414 !important",
          padding: "0 16px !important",
          minHeight: "auto !important",
          height: "32px !important",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start !important",
          outline: 0,
          borderRadius: "0 !important",
          "&.active": {
            color: "#000 !important",
            backgroundColor: "#F6F6F6 !important"
          },
          "& .label": {
            fontSize: "14px"
          }
        }}
        endIcon={open ? <Icons name="arrow-down" /> : <Icons name="arrow-up" />}
        className={getClassNameActive(data, currentPath)}
        onClick={handleClick}
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data.subMenu.map((item, index) => (
            <SubMenuItem
              key={index}
              className={getClassNameActive(item, currentPath)}
              onClick={onRedirect(item.path)}
            >
              <ListItemText primary={item.title} />
            </SubMenuItem>
          ))}
        </List>
      </Collapse>
    </Stack>
  )
}

export default SideNavWithSubMenu
