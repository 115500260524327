import * as React from "react"

import { CustomButton, Icons } from "@src/components"
import Stack from "@mui/material/Stack"
import Popover from "@mui/material/Popover"
import MenuItem from "@mui/material/MenuItem"
import { usePopover } from "@src/hooks"
import { styled } from "@mui/system"
import { getClassNameActive } from "../config"

const MenuItemCustom = styled(MenuItem)(({ theme }) => ({
  color: "#141414 !important",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "24px",
  padding: "0 16px",
  minHeight: "48px !important",
  minWidth: 200,
  borderBottom: "1px solid #E5E5E5",
  "&:hover": {
    color: "#000 !important",
    backgroundColor: "#F6F6F6 !important"
  },
  "&.active": {
    color: "#000 !important",
    backgroundColor: "#F6F6F6 !important"
  }
}))

const MenuWithSubItem = props => {
  const { currentPath, data, onRedirect } = props || { data: {} }
  const popoverHook = usePopover()

  const currentSubMenu = data.subMenu.filter(s => currentPath.includes(s.path))
  const btnLabel =
    currentSubMenu.length > 0 ? currentSubMenu[0].title : data.title

  const handleClickItem = path => () => {
    onRedirect(path)()
    popoverHook.handleClose()
  }

  return (
    <Stack>
      <div ref={popoverHook.anchorRef}>
        <CustomButton
          disableRipple
          label={btnLabel}
          sx={{
            position: "relative",
            backgroundColor: "transparent !important",
            border: "none !important",
            padding: "0 !important",
            color: "#808080 !important",
            minHeight: "auto !important",
            outline: 0,
            "&.active span": {
              color: "#000 !important",
              fontWeight: 700
            },
            "&:hover > span": {
              color: "#000 !important",
              fontWeight: 700
            },
            span: {
              fontSize: "16px !important"
            }
          }}
          endIcon={
            <Icons
              name="arrow-up"
              sx={{
                transform: popoverHook?.open ? "rotate(0deg)" : "rotate(180deg)"
              }}
            />
          }
          className={
            popoverHook?.open || getClassNameActive(data, currentPath)
              ? "active"
              : ""
          }
          onClick={popoverHook.handleToggle}
        />
      </div>

      <Popover
        open={popoverHook.open}
        onClose={popoverHook.handleClose}
        anchorEl={popoverHook.anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom"
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "4px",
            marginTop: "4px"
          }
        }}
      >
        {data.subMenu.map((item, index) => (
          <MenuItemCustom
            key={index}
            onClick={handleClickItem(item.path)}
            className={getClassNameActive(item, currentPath)}
          >
            {item.title}
          </MenuItemCustom>
        ))}
      </Popover>
    </Stack>
  )
}

export default MenuWithSubItem
