"use client"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { NextLink, NextImage } from "@src/components"
import Icons from "../components/Icons"
import Logo from "../components/logo"
import { useResponsive } from "@src/hooks"

const Footer = () => {
  const { isMobile } = useResponsive()

  return (
    <Box>
      <Box
        component="footer"
        sx={{
          backgroundColor: "transparent",
          zIndex: theme => theme.zIndex.appBar,
          pb: {
            xs: 4,
            md: "20px"
          },
          pt: {
            xs: 5,
            md: "80px"
          },
          paddingX: {
            xs: "16px",
            md: "44px"
          },
          maxWidth: "xl",
          margin: "auto"
        }}
      >
        <Grid
          container
          spacing={{
            xs: 3,
            md: 0
          }}
          direction={{
            xs: "column-reverse",
            md: "row"
          }}
        >
          <Grid item lg={9} md={8} xs={12}>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              height={"100%"}
              sx={{
                "& > svg": {
                  width: {
                    xs: "98.56px",
                    md: "295.68px"
                  },
                  height: {
                    xs: "16px",
                    md: "48px"
                  }
                }
              }}
            >
              <Logo />
              <NextImage
                src="/assets/images/bo-cong-thuong.png"
                alt="Bộ công thương"
                sx={{
                  width: {
                    xs: "120px",
                    md: "200px"
                  },
                  height: {
                    xs: "60px",
                    md: "80px"
                  },
                  marginTop: {
                    xs: "12px",
                    md: "24px"
                  }
                }}
              />
              <Stack
                direction={"column"}
                mt={{
                  xs: 2,
                  md: 8
                }}
                spacing={1.2}
              >
                <Typography>CÔNG TY TNHH THƯƠNG MẠI THE C.I.U</Typography>
                <Typography>
                  Địa chỉ: 64 Tân Canh, Phường 1, Quận Tân Bình, Thành phố Hồ Chí Minh
                </Typography>
                <Typography>Mã số doanh nghiệp: 0317478388</Typography>
                <Typography>Ngày hoạt động: 16/09/2022</Typography>
                <Typography>Email: cskh@theciu.vn</Typography>
                <Typography>
                  Hotline:
                  <a
                    href="tel:0775665912"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <b>0775665912</b>
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Box>
              <Typography sx={{ fontWeight: 600, mb: 1 }}>Social</Typography>
              <Stack gap={2}>
                <NextLink href="https://www.facebook.com/theciusaigon" target="_blank">
                  <Icons
                    sx={{
                      padding: 0
                    }}
                    name="facebook"
                    type="button"
                  />
                </NextLink>
                <NextLink href="https://www.instagram.com/theciusaigon" target="_blank">
                  <Icons
                    sx={{
                      padding: 0
                    }}
                    name="instagram"
                    type="button"
                  />
                </NextLink>
                <NextLink href="https://www.tiktok.com/@theciusaigon" target="_blank">
                  <Icons
                    sx={{
                      padding: 0
                    }}
                    name="tiktok"
                    type="button"
                  />
                </NextLink>
              </Stack>
            </Box>
            <Stack
              direction={"column"}
              mt={{
                xs: 2,
                md: 8
              }}
              spacing={0.5}
            >
              <Typography sx={{ fontWeight: 600 }}>Thông tin</Typography>
              <NextLink href="/" colorHover={"black"}>
                Chính sách kiểm hàng
              </NextLink>
              <NextLink href="/" colorHover={"black"}>
                Chính sách bảo hành
              </NextLink>
              <NextLink href="/" colorHover={"black"}>
                Hỗ trợ dịch vụ
              </NextLink>
              <NextLink href="/" colorHover={"black"}>
                Liên hệ
              </NextLink>
              <NextLink href="/" colorHover={"black"}>
                Chính sách bảo mật
              </NextLink>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
