import { ROUTES } from "@src/constants/routes"

export const getClassNameActive = (data, currentPath) => {
  if (!currentPath) {
    return ""
  }

  if (data?.subMenu) {
    const subMenu = data?.subMenu?.find(item =>
      currentPath.startsWith(item.path)
    )
    if (subMenu) {
      return "active"
    }
  }

  if (
    data?.isHome &&
    ["/", "/#", ROUTES.BEST_SELLER, ROUTES.NEW_IN, ROUTES.ON_SALE].includes(
      currentPath
    )
  ) {
    return "active"
  }

  if (!data?.isHome && currentPath.includes(data.path)) {
    return "active"
  }
  return ""
}

export const menuConfig = [
  {
    title: "Trang chủ",
    isHome: true,
    path: ROUTES.HOME_PAGE
  },
  {
    title: "Collection",
    path: ROUTES.COLLECTION
  },
  {
    title: "Sản phẩm",
    path: ROUTES.PRODUCT
  },
  {
    title: "Event",
    path: ROUTES.EVENT
  },
  {
    title: "Giới thiệu",
    path: ROUTES.INTRODUCE,
    subMenu: [
      {
        title: "About Us",
        path: ROUTES.ABOUT_US
      },
      {
        title: "Membership",
        path: ROUTES.MEMBERSHIP
      },
      {
        title: "Chi nhánh",
        path: ROUTES.BRANCH
      },
      {
        title: "Tuyển dụng",
        path: ROUTES.RECRUITMENT
      },
      {
        title: "FAQ",
        path: ROUTES.FAQ
      }
    ]
  },
  {
    title: "Blog",
    path: ROUTES.BLOG
  }
]
