import Box from "@mui/material/Box"
import Fade from "@mui/material/Fade"
import Popper from "@mui/material/Popper"
import * as React from "react"
import SearchContent from "./search-content"

export default function SearchPopover(props) {
  const [parentWidth, setParentWidth] = React.useState(0)
  const {
    open,
    anchorEl,
    searchValue,
    searching,
    searchList = [],
    suggestKeyword = []
  } = props

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? props?.id || "transition-popper" : undefined

  React.useEffect(() => {
    if (canBeOpen && !parentWidth) {
      setParentWidth(anchorEl.clientWidth + 48)
    }
  }, [canBeOpen])

  return (
    <Popper
      sx={{
        zIndex: 9999,
        width: parentWidth || "auto"
      }}
      id={id}
      open={canBeOpen && parentWidth}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
              padding: 3,
              marginTop: 1.5,
              marginLeft: "-20px",
              gap: 3,
              boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
              overflowX: "hidden"
            }}
          >
            <SearchContent
              searchValue={searchValue}
              searching={searching}
              searchList={searchList}
              suggestKeyword={suggestKeyword}
            />
          </Box>
        </Fade>
      )}
    </Popper>
  )
}
