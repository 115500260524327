import Divider from "@mui/material/Divider"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Popover from "@mui/material/Popover"
import { useRouter } from "next/navigation"
import PropTypes from "prop-types"
import { useCallback } from "react"
import { Icons } from "@src/components"
import { SIDE_BAR_CONFIG } from "@src/constants/account"
import { ROUTES } from "@src/constants/routes"
import { useCurrentUser } from "@src/hooks/use-current-user"
import { signOut } from "next-auth/react"

interface Props {
  anchorEl: any
  onClose: () => void
  open: boolean
}

const AccountPopover = (props: Props) => {
  const { anchorEl, onClose, open } = props
  const router = useRouter()


  const handleRedirect = useCallback(
    path => () => {
      onClose?.()
      router.push(path)
    },
    []
  )

  const handleSignOut = () => {
    signOut({
      callbackUrl: ROUTES.SIGN_IN
    })
  }
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      // placement="bottom-end"
      onClose={onClose}
      open={open}
    >
      <MenuList
        disablePadding
        dense
        sx={{
          "& .MuiMenuItem-root": {
            fontWeight: "bold",
            fontSize: "14px",
            p: 2
          }
        }}
      >
        {SIDE_BAR_CONFIG.map((item, index) => (
          <>
            <MenuItem
              key={index}
              onClick={handleRedirect(item.path)}
              sx={{
                color: "#000",
                "& span": {
                  fontWeight: 400,
                }
              }}
            >
              <Icons
                name={item.iconName}
                sx={{
                  mr: 1
                }}
              />
              <span>{item.label}</span>
            </MenuItem>
            <Divider
              sx={{
                width: "90%",
                m: "0px auto !important"
              }}
            />
          </>

        ))}

        <MenuItem
          onClick={handleSignOut}
          sx={{
            color: "#000",
            "& span": {
              fontWeight: 400,
            }
          }}
        >
          <Icons
            name="power"
            sx={{
              mr: 1
            }}
          />
          <span>Đăng xuất</span>
        </MenuItem>
      </MenuList>
    </Popover>
  )
}

export default AccountPopover

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
}
